const Minimax = require('./minimax');
  
export default function Board({gameState, handleClickSquare}) {

  function renderSquare(i) {
    let className = "square";
    if(gameState.state === Minimax.GameState.X_WINS || gameState.state === Minimax.GameState.O_WINS) {
      if (gameState.line.includes(i)) {
        className = "winner";
      }
    }
    return (
      <div className={className} onClick={() => handleClickSquare(i)}>
        {gameState.squares[i]}
      </div>
    );
  }

  return (
    <div className="board">
      {renderSquare(0)}
      {renderSquare(1)}
      {renderSquare(2)}
      {renderSquare(3)}
      {renderSquare(4)}
      {renderSquare(5)}
      {renderSquare(6)}
      {renderSquare(7)}
      {renderSquare(8)}
    </div>
  );
}