class Minimax {

    static GameState = {
        PLAYING: 0,
        X_WINS:  1,
        O_WINS:  2,
        DRAW:    3
    };

    static SquareState = {
        EMPTY: '',
        X: 'X',
        O: 'O'
    };
    
    checkWin(board) {

        const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
        ];
    
        for (let line of lines) {
            if (line.every((value) => board[value] === 'X')) {
                return { state: Minimax.GameState.X_WINS, line: line };
            } else if (line.every((value) => board[value] === 'O')) {
                return { state: Minimax.GameState.O_WINS, line: line };
            }
        }
        
        if (board.every((value) => value !== Minimax.SquareState.EMPTY)) {
            return { state: Minimax.GameState.DRAW };
        }
        
        return { state: Minimax.GameState.PLAYING };
    }

    minimax(board, depth, isMaximizing) {
        let result = this.checkWin(board);
        if (result.state !== Minimax.GameState.PLAYING) {
            if (result.state === Minimax.GameState.X_WINS) {
                return { score: 10 - depth };
            } else if (result.state === Minimax.GameState.O_WINS) {
                return { score: -10 + depth};
            } else if (result.state === Minimax.GameState.DRAW) {
                return { score: 0 };
            }
        }

        if (isMaximizing) {
            let bestScore = -Infinity;
            let move;
            for (let i = 0; i < 9; i++) {
                // Check if spot is available
                if (board[i] === Minimax.SquareState.EMPTY) {
                    board[i] = Minimax.SquareState.X;
                    let score = this.minimax(board, depth + 1, false).score;
                    board[i] = Minimax.SquareState.EMPTY;
                    if (score > bestScore) {
                        bestScore = score;
                        move = i;
                    }
                }
            }
            return { score: bestScore, move: move };
        } else {
            let bestScore = Infinity;
            let move;
            for (let i = 0; i < 9; i++) {
                // Check if spot is available
                if (board[i] === Minimax.SquareState.EMPTY) {
                    board[i] = Minimax.SquareState.O;
                    let score = this.minimax(board, depth + 1, true).score;
                    board[i] = Minimax.SquareState.EMPTY;
                    if (score < bestScore) {
                        bestScore = score;
                        move = i;
                    }
                }
            }
            return { score: bestScore, move: move };
        }
    }
}

module.exports = Minimax;