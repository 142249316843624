import React, { useState } from 'react';
import './App.css';
import Board from './Board';
import Status from './Status';
import Minimax from './minimax';

function App() {
  
  const squares = Array(9).fill(Minimax.SquareState.EMPTY);
  squares[Math.floor(Math.random() * 9)] = Minimax.SquareState.X;
 
  const [gameState, setGameState] = useState({state: Minimax.GameState.PLAYING, squares: squares});
  const minimax = new Minimax();

  function handleClickSquare(index) {
    const squares = gameState.squares.slice();
    if (gameState.state === Minimax.GameState.PLAYING && squares[index] === Minimax.SquareState.EMPTY) {
      squares[index] = Minimax.SquareState.O;
      let result = minimax.minimax(squares, 0, true);
      squares[result.move] = Minimax.SquareState.X;
      result = minimax.checkWin(squares);
      setGameState({state: result.state, squares: squares, line: result.line});    
    }
  }

  return (
    <div className="App">
      <h1 className="header">Let's Draw!</h1>
      <Board gameState={gameState} handleClickSquare={handleClickSquare}/>
      <Status gameState={gameState} />
    </div>
  );
}

export default App;