import Minimax from './minimax';

export default function Status({gameState}) {
  let statusText = '';
  if (gameState.state === Minimax.GameState.X_WINS) {
    statusText = 'You Lose!';
  } else if (gameState.state ===  Minimax.GameState.DRAW) {
    statusText = 'Draw!';
  }        
  return (
    <div className="status">
      {statusText}
    </div>
  );
}